<template lang="pug">
div.content
  van-tabs(v-model='active')
    van-tab(
      v-for="(tab,index) of tabs"
      :key="index"
      :title='tab.name'
      )
      component(:is="tab.component" v-if="active == index" :value='tabData[tab.name]')
</template>

<script>

import ProjectSchedule from './_projectSchedule'
import DesignTask from './_designTask'

export default {
  props: ['value'],
  components: {
    ProjectSchedule,
    DesignTask,
  },
  data() {
    return {
      active: 0,
      data: {},
      tabs:[
        {
          name: '进度总览',
          component: ProjectSchedule
        },
        {
          name: '设计信息',
          component: DesignTask,
        },
      ],
      tabData:{
        进度总览: {},
        设计信息: []
      }
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    }
  },
  methods: {
    init(obj) {
      // console.log(obj)
      // this.data = obj
      // this.tabData['进度总览'] = obj
      // this.tabData['设计信息'] = '12312'
      this.initData(obj.id)
    },
    async initData(id){
       try {
        let res = await this.$api.GetEyasOrderItem(id)
        this.tabData['进度总览'] = res.data
        if (res.data.item_rel_eyas_task.count > 0) {
          let tasks = res.data.item_rel_eyas_task.items
          this.getDesignTask(tasks)
        }
        // console.log(res.data)
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    getDesignTask(tasks) {
      let task = tasks.find((task) => {
        return task.process === '设计'
      })
      if (task && task.task_rel_eyas_progress_record) {
        // 设计任务进度记录
        if (task.task_rel_eyas_progress_record.length > 0) {
          let records = task.task_rel_eyas_progress_record
          // 只取最新的一条记录
          if (records.length > 0) {
            this.tabData['设计信息'] = records[0].record_rel_eyas_attachment.items
          }
        }
      }
    },
  },
  mounted() {
    this.init(this.value)
  }
}
</script>

<style scoped>
  .card-img-top{
    width:100%;
    border-top-left-radius:calc(.25rem - 1px);
    border-top-right-radius:calc(.25rem - 1px);
  }
  .content {
    padding: 16px 16px 160px;
  }
</style>