<template lang="pug">
div
  div(v-if="attachments.length > 0"
    v-for="att of attachments" 
    :key="att.id" 
    )
    span {{att.filename}}
    van-image(
      :src="att.file_url"
    )
  div(v-else)
    | 无数据
</template>

<script>
export default {
  name: 'DesignTask',
  props: [
    'value'
  ],
  data() {
    return {
      attachments: [
        // {
        //   id: 0,
        //   filename: '11111',
        //   // file_url: 'http://cimg.dpjia.com/files/uploads/images/788b155d400b55ae72196681cf6cc743.jpg'
        // },
        // {
        //   id: 1,
        //   filename: '2222',
        //   file_url: 'http://cimg.dpjia.com/files/uploads/images/788b155d400b55ae72196681cf6cc743.jpg'
        // }
      ]
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    }
  },
  methods: {
    init(obj) {
      // console.log(obj)
      this.attachments = obj
    }
  },
  mounted() {
    this.init(this.value)
  }
}
</script>
