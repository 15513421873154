<template lang="pug">
div
  img.card-img-top( :src='data.thumbnail_url' fit='fill')
  ve-histogram(:data='chartData'
    :settings="chartSettings"
    :extend='extend'
    :tooltip-visible="true"
    :legend-visible="true")
</template>

<script>

import VeHistogram from 'v-charts/lib/histogram.common'
export default {
  props: ['value'],
  components: {
    [VeHistogram.name]: VeHistogram,
  },
  data() {
    this.chartSettings = {
      stack: { '用户': ['访问用户', '下单用户'] },
      yAxisName: ['完成率%'],
      min:[0],
      max:[100],
      scale:[true,true]
    }
    this.extend = {
      series: {
        label: { show: true, position: "top" }
      }
    }
    return {
      data: {},
      chartData: {
        columns: ['名称', '完成率'],
        rows: [
          // { '名称': '设计',  '完成率': 10},
          // { '名称': '开料',  '完成率': 50},
          // { '名称': '封边',  '完成率': 40},
          // { '名称': '打孔',  '完成率': 33},
          // { '名称': '油漆',  '完成率': 0},
          // { '名称': '入库',  '完成率': 88}
        ]
      }
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    }
  },
  methods: {
    init(obj) {
      if(!obj.process) return
      this.data = obj
      let process = obj.process.split(',')
      let chartDataRows = []
      process.map((p) => {
        let d = obj.item_rel_eyas_task.items.find((i) => {
          return i.process === p
        })
        let rate = d === undefined ? 0 : parseFloat(d.progress_rate) * 100
        chartDataRows.push({
          '名称': p,
          '完成率': rate
          })
      })
      this.chartData.rows = chartDataRows
    },
  },
  mounted() {
    this.init(this.value)
  }
}
</script>

<style scoped>
  .card-img-top{
    width:100%;
    border-top-left-radius:calc(.25rem - 1px);
    border-top-right-radius:calc(.25rem - 1px);
  }
</style>