<template lang="pug">
div
  van-card.card
    template(#num)
      van-circle(v-model='data.completion_rate*100', :text='text' :stroke-width="60" size="64px")
    template(#price) 
      p(v-for="(field,index) of fields" 
        :key='index')
        span {{field.label}}:
          | {{data[field.key]}}
  van-cell-group(title='商品信息') 
  div(v-if='data.order_rel_eyas_production_order_item && data.order_rel_eyas_production_order_item.items' )
    van-card.card(v-for='(item,index) of data.order_rel_eyas_production_order_item.items'  
      :key='index'
      :thumb="item.thumbnail_url"
      :title='item.name'
      @click="onCardClick(item)"
      )
      template(#num)
        van-circle(:value='item.value', :rate='item.count_num', :text='circleText(item)' :stroke-width="80" size="64px")
      template(#price) 
        p(v-for="(field,index) of itemFields" 
          :key='index')
          span {{field.label}}:
            | {{item[field.key]}}
  van-action-sheet(v-model='show', :title='subDetailTitle' style='height:100%;')
    SubDetail(v-if='show' :value='currentItem')
        
</template>

<script>
import { Circle } from 'vant';
import SubDetail from './_subdetail'

export default {
  components: {
    [Circle.name]: Circle,
    SubDetail
  },
  computed: {
    text() {
      return (this.data.completion_rate*100).toFixed(0) + '%';
    },
    subDetailTitle() {
      return `${this.currentItem.name} 进度信息 `
    },
    companyInfo(){
      return this.$store.state.companyInfo
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  data () {
    return {
      show: false,
      currentRate: 0,
      itemFields:[
        { key: 'color', label: '颜色'},
        { key: 'sizes', label: '尺寸'},
        { key: 'count_num', label: '总数量'},
        { key: 'completed_number', label: '完成数量'},
        { key: 'other_num', label: '未完成数量'},
        { key: 'unit', label: '单位'},
      ],
      fields:[
        { key: 'name', label: '订单名称'},
        { key: 'project_name', label: '项目名称'},
        { key: 'cycle_time', label: '下单日期'},
        { key: 'delivery_time', label: '交货日期'},
        { key: 'start_time', label: '开始日期'},
        { key: 'expected_end_time', label: '预计结束日期'},
      ],
      data: {
        // id: "47",
        // order_poi_ofc_order: "0",
        // completion_rate: "0.23",
        // name: "19-11-205",
        // number: "19-11-205",
        // project_name: "19-11-205",
        // project_number: "19-11-205",
        // cycle_time: "1574179200000",
        // delivery_time: "1574179200000",
        // start_time: "1574179200000",
        // expected_end_time: "1574179200000",
        // end_time: "0",
        // warning_time: "1574179200000",
        // process: "设计,板式车间-生产,实木车间-生产,软体车间-生产,油漆车间-生产,入库",
        // com_id_poi_companys: "96",
        // uid_poi_company_admin: "130",
        // create_time: "1576981597030",
        // update_time: "1576981597030",
        // order_rel_eyas_production_order_item:{
        //   items:[
        //     {
        //       id:"218",
        //       order_poi_eyas_production_order:"48",
        //       modification_status:"",
        //       count_num:"50",
        //       original_num:"1",
        //       production_value:"0.06",
        //       unit:"件",
        //       process:"设计,板式车间-生产,实木车间-生产,软体车间-生产,油漆车间-生产,入库",
        //       thumbnail_url:"http://cimg.dpjia.com/images/b_art_img.jpg",
        //       name:"床头柜",
        //       number:"19-11-205-1",
        //       sizes:"500*450*550",
        //       color:"默认",
        //       remark:"",
        //       com_id_poi_companys:"96",
        //       create_time:"1576981597078",
        //       update_time:"1576981597078",
        //       mask_delete:"0",
        //       item_rel_eyas_task:{
        //         items:[

        //         ],
        //         count:0
        //       },
        //       completed_number:49
        //     },
        //     {
        //       id:"219",
        //       order_poi_eyas_production_order:"48",
        //       modification_status:"",
        //       count_num: 40,
        //       original_num:"1",
        //       production_value:"0.00",
        //       unit:"把",
        //       process:"设计,板式车间-生产,实木车间-生产,软体车间-生产,油漆车间-生产,入库",
        //       thumbnail_url:"http://cimg.dpjia.com/images/b_art_img.jpg",
        //       name:"餐椅",
        //       number:"19-11-205-2",
        //       sizes:"420*520*920H",
        //       color:"默认",
        //       remark:"",
        //       com_id_poi_companys:"96",
        //       create_time:"1576981597126",
        //       update_time:"1576981597126",
        //       mask_delete:"0",
        //       item_rel_eyas_task:{
        //         items:[

        //         ],
        //         count:0
        //       },
        //       completed_number: 20,
        //     },
        //   ]
        // },
      },
      id: 0,
      isLoading: false,
      order: {},
      items: [],
      currentItem: {}
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.back(-1)
      },
      rightText: '个人中心',
      rightArrow: true,
      onClickRight: ()=> {
        this.$router.push('person')
      },
    })
    this.init()
  },
  methods: {
    onCardClick(obj) {
      this.currentItem = obj
      this.show = true
    },
    circleText(order) {
      let result =  `${order.completed_number}/${order.count_num}`;
      // console.log(2222,order.completed_number,order.count_num)
      order.value = (order.completed_number/order.count_num) * 100
      return result
    },
    async init() {
      // console.log('init')
      // this.data = {
      //   ...this.data,
      //   create_time: this.dataFormat(this.data.create_time),
      //   cycle_time: this.dataFormat(this.data.cycle_time),
      //   delivery_time: this.dataFormat(this.data.delivery_time),
      //   start_time: this.dataFormat(this.data.start_time),
      //   expected_end_time:this.dataFormat(this.data.expected_end_time),
      //   // order_rel_eyas_production_order_item:  {
      //   //   items: this.data.order_rel_eyas_production_order_item.map(p=>{
      //   //     return {
      //   //       ...p,
      //   //       value: parseInt(p.count_num) - parseInt(p.completed_number),
      //   //       circleText:  `${p.completed_number}/${p.count_num}`,
      //   //       completed_number: p.completed_number
      //   //     }
      //   //   }) 
      //   // },
      // }
      try {
        let params = {
          id: this.id,
          where: {
              com_id_poi_companys : this.companyInfo.id,
              seller_poi_sell_users: this.sellerId
            }
        }
        let res = await this.$api.GetEyasOrderDetail(params)
        // console.log(res.data)
        this.data =  {
            ...res.data,
            create_time: this.dataFormat(res.data.create_time),
            cycle_time: this.dataFormat(res.data.cycle_time),
            delivery_time: this.dataFormat(res.data.delivery_time),
            start_time: this.dataFormat(res.data.start_time),
            expected_end_time:this.dataFormat(res.data.expected_end_time),
        }
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    }
  },
}
</script>

</script>
<style scoped>
.card{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid rgba(0,0,0,.125);border-radius:.25rem}
.text-danger{color:#dc3545!important}
.text-warning{color:#ffc107!important}
.text-success{color:#28a745!important}
</style>

